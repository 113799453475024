<!-- Finder Facturas Expedientes -->
<template>
  <div :class="`item ${selected? 'selected':''}`">    
    <div @click="clickItem">  
      <div class="conflex">
        <div class="column" style="width:25%">
          {{ source.control }}
          <div class="light-blue--text text--darken-3"> {{ source.fhhr|fechahr }}</div> 
        </div>

        <div class="column" style="width:40%">
          {{ source.fallename }}
          <div class="light-blue--text text--darken-3"> {{ source.name_callemp }}</div> 
          <div class="light-green--text text--darken-3"> {{ source.callref1 }}</div>
        </div>

        <div class="column" style="width:35%">
          {{ source.fallefhhr|fechahr }}
          <div class="light-blue--text text--darken-3"> {{ source.name_fune1 }}</div> 
          <div class="light-green--text text--darken-3"> {{ source.poliza }}</div> 
        </div>
      </div>     
    </div>
  </div>
</template>



<script>
export default {
  name: 'fras_Exp_F_item',
  props: {
    source: { type: Object, default() { return {}; }},
    selectedItem: { type: Object, default() { return {}; }}   
  },


  computed: {
    selected() {
      if (!this.selectedItem || !this.selectedItem.id) return false;
      if (this.selectedItem.id== this.source.id) return true;      
      return false;
    }
  },


  methods: {
    clickItem() {
      let parent = this.$parent || this.$root
      let name = parent.$options.name

      while (parent && (!name || name != 'fras_Exp_F')) {
        parent = parent.$parent
        if (parent) {
          name = parent.$options.name
        }
      }

      if (parent) parent.$emit.apply(parent, ['vsEvent'].concat(this.source));    
    }
  }
}
</script>


<style scoped>
  .item {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    border-bottom: 1px solid lightgrey;
    padding: 1em;
  }

  .selected {
    background-color: lightgrey;
  }

</style>